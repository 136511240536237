<template>
<div id="app">
    <router-view :baseUrl="baseUrl"></router-view>
</div>
</template>

<script>
export default {
    name: 'App',
    components: {

    },
    data() {
        return {
            baseUrl: "https://serverforge.joinyara.com/admin"
        }
    },
    computed: {
        isLoggedin() {
            return this.$store.getters.isAuthenticated;
        },
        getRouteName() {
            let rname = this.$route.name;
            var compact_menu = ["RefillQuestions", "ThankYouRevisit", "CheckoutForm", "Calendar", "ThanksAppointment", "HomePage", "LoginPage", "ForgotPassword", "ResetPassword"];
            if (compact_menu.includes(rname)) {
                return true;
            } else {
                return false;
            }

        }
    },
    watch: {
        isLoggedin: function (newVal, oldVal) {
            console.log(oldVal)
            this.pushToRoute(newVal);
        },
        $route() {
            
        }
    },
    mounted() {
       
        
    },
    methods: {
        
        
        pushToRoute(val) {
            console.log(val);

        },
    },
}
</script>

<style>
.hidden-widget {
    display: none;
}
</style>
