<template>
<div>
    <div id="mygalModal" aria-hidden="true" class="modal fade move-up in intro-modal modal-for-gal show" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="new-modal">
                    <div class="modal_down bacground_sdo">
                        <div class="intro-modal-body">
                            <div class="intro-modal-heading"><strong>Refund</strong> </div>
                            <div class="trial-details">
                                <form @submit.prevent="ProcessRefund()">
                                    <div class="preview" style="padding-top: 35px !important;">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Transaction Id</label>
                                            <input type="text" v-model="transaction.transaction_id" class="form-control" id="exampleInputUsername1" placeholder="Transaction Id" required>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Enter Amount</label>
                                            <input type="text" v-model="transaction.amount" class="form-control" id="exampleInputUsername1" placeholder="Enter Amount" required>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Enter Reason Of Refund</label>
                                            <input type="text" v-model="reason" class="form-control" id="exampleInputUsername1" placeholder="Reason Of Refund" required>
                                        </div>
                                    </div>
                                    <div class="footer-button" style="padding-top: 35px !important;">
                                        <div class="div-block-1120">
                                            <button class="btn btn-dark btn-fw">
                                                <span v-if="submitted"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                        <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                        </circle>
                                                    </svg></span>
                                                <span v-else>Submit</span>
                                            </button>
                                            &nbsp;
                                            <a href="#" @click="CloseDialog($event)" class="btn btn-secondary btn-fw">Cancel</a>
                                        </div>
                                    </div>
                                </form>
                                <div class="w-form-fail" style="display: block;color:red; padding-top:25px; display:flex;align-items: center; justify-content: center;" v-if="err">
                                    <div>{{errorReason}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: ["baseUrl", "trans"],
    data() {
        return {
            mykey: 1,
            transaction: null,
            errorReason: '',
            submitted: false,
            err:false,
            reason:'',
        }
    },
    mounted() {
        this.createCardElement();
    },
    methods: {
        ProcessRefund() {
            this.submitted=true;
            this.err= false;
            let post = {
                ...this.transaction,
                reason: this.reason
            }
            window.axios.post(this.baseUrl + '/refund', post)
                .then(response => {
                    if(response.data.status=="success"){
                        this.$emit("ShowToast");
                    }else{
                        this.err = true;
                        this.errorReason= response.data.message;
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                });
        },

        CloseDialog(event) {
            event.preventDefault();
            this.$emit("CloseBox");
        },
    },
    created() {
       this.transaction = this.trans;
    }
}
</script>

<style scoped>
.modal {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

}

.intro-modal-body {
    justify-content: center;
}

.intro-modal-heading {
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.modal-content {
    background-color: #eefcff;
    border-radius: 10px;
    max-width: 468px;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}
.footer-button{
    justify-content: space-around;
    align-items: center;
    display: flex;
}
.dash-button {
    height: 45px;
}
</style>
