import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'


//import QuestionForm1 from '@/components/Webpages/backupForms/QuestionForm1.vue';
import HomePage from '@/components/Dashboard/homePage.vue';
import LoginPage from '@/components/Auth/LoginPage.vue';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
      meta: {
        requiresAuth: true,
        guest: false
      }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/*',
      name: '404',
      component: LoginPage,
      meta: {
        guest: true
      }
    },
  ],

})

router.beforeEach((to, from, next) => {
  const currentParams = from.query;
  const targetParams = to.query;
  const mergedParams = { ...currentParams, ...targetParams };
  const paramsDiffer = JSON.stringify(targetParams) !== JSON.stringify(mergedParams);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authToken == null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }else if(to.name == "LoginPage" && store.getters.authToken !== null){
    next({
      path: '/home'
    })
  } else if (paramsDiffer) {
    next({
      ...to,
      query: mergedParams, // Include merged parameters
    });
  } else {
    next();
  }
})
export default router;
