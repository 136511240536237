<template>
    <div>
      <div v-bind:class="{ 'show': show}" class="toast">
        {{ message }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:["message"],
    data() {
      return {
        show: true,
      };
    },
    methods: {
      showToast() {
        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 6000);
      }
    },
    created(){
        this.showToast()
    }
  };
  </script>
  
  <style>
  .toast {
  visibility: hidden;
  min-width: 250px;
  margin: auto;
  background-color: #eefcff;
  color: #000;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.toast.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    right: 30px;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}
  </style>
  