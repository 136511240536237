<template>
<div>
    <div class="container-scroller" style="background: #eefcff;">
        <div class="container-fluid page-body-wrapper full-page-wrapper" style="background: #eefcff;">
            <div class="content-wrapper d-flex align-items-center auth" style="background: #eefcff;">
                <div class="row flex-grow">
                    <div class="col-lg-4 mx-auto">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo" style="display: flex; justify-content: center; align-items: center;">
                                <img src="@/assets/images/yara-placeholder.svg">
                            </div>
                            <h4></h4>
                            <h6 class="font-weight-light">Sign in to continue.</h6>
                            <form class="pt-3"  style="justify-content: center; align-items: center;" @submit.prevent="onSubmit()">
                                <div class="form-group">
                                    <input type="email" v-model="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username">
                                </div>
                                <div class="form-group">
                                    <input type="password" v-model="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password">
                                </div>
                                <div class="mt-3 d-grid gap-2">
                                    <button class="btn btn-dark btn-fw">
                                        <span v-if="submitted"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                </circle>
                                            </svg></span>
                                        <span v-else>Log In</span>
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- content-wrapper ends -->
        </div>
        <!-- page-body-wrapper ends -->
    </div>
</div>
</template>

<script>
export default {
    name: 'LoginPage',
    props: ['baseUrl'],
    data() {
        return {
            email: null,
            password: null,
            submitted: false,
            err: false,
            errmsg: "",
        }
    },
    methods: {
        onSubmit() {
            this.submitted = true;
            this.err = false;
            window.axios.post('https://serverforge.joinyara.com/api/admin-login', {
                password: this.password,
                email: this.email,
            }).then(response => {
                if (response.data.status == 'success') {
                    this.$store.dispatch("doLogin", response.data);
                    this.$router.replace("/");
                } else {
                    this.submitted = false;
                    this.err = true;
                    this.errmsg = response.data.message;
                }
            }).catch(error => {
                console.log(error)
                this.submitted = false;
                this.err = true;
                this.errmsg = 'Email or password entered is incorrect. Please try again or <router-link to="/forgot-password">reset your password.</router-link>'
            });

        },
    }
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
    button.link-block-9.w-inline-block {
        padding: 0;
        margin-bottom: 15px;
    }

    .section-8 .text-block-47 {
        margin-bottom: 25px;
    }
}
</style>
